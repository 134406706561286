import { Box, HStack, useBreakpointValue } from '@chakra-ui/react';
import { FC } from 'react';

import { ActionGroup } from '$/components/core/Editor/ActionBar/components/ActionGroup';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useKeyPress } from '$/hooks/useKeyPress';
import { EditorZoomSection } from '$/pages/EditorPage/components/ActionbarSection/components/EditorZoomSection';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorComponent } from '$/services/usecases/editor/mapper/editorStatus';

interface Props {
  groups: EditorActionGroup[];
}

const filterGroups = (
  groups: EditorActionGroup[],
  component: EditorComponent | null,
) => {
  if (component == null || component?.mode !== 'bitmap') {
    return groups;
  }

  return groups.filter((group) => group.actions.length > 0);
};

export const ActionBarSection: FC<Props> = ({ groups }) => {
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const { selectAction } = useEditorActions();
  const isFullscreen = useEditorStore.useIsFullScreen();
  const groupAll = useBreakpointValue({ base: true, md: false });

  useKeyPress(
    ({ key }) =>
      key === 'Escape' && selectAction(ModuleSendSelectAction.SelectPoly),
  );

  const nonEmptyGroups = filterGroups(
    groups,
    activeComponentIndex == null ? null : components[activeComponentIndex],
  );

  const completeGroupActions = groups
    .filter((group) => group.groupable)
    .flatMap((group) => group.actions);

  const completeGroupAction: EditorActionGroup = {
    id: 'completeGroup',
    groupable: true,
    actions: completeGroupActions,
  };

  const [selectGroup, ...remainingGroups] = groups.filter(
    (group) => !group.groupable,
  );

  const shownGroups = groupAll
    ? [selectGroup, completeGroupAction, ...remainingGroups]
    : nonEmptyGroups;

  return (
    <HStack
      as='section'
      layerStyle='FloatingActionBar'
      top={isFullscreen ? '-50%' : '0'}
      px={{ base: '4', md: '16' }}
      transition='top 0.5s ease'
    >
      <HStack gap='0' h='full'>
        {shownGroups.map((group) => (
          <HStack key={group.id} gap='0' h='100%'>
            <Box display={{ base: 'none', xl: 'flex' }}>
              <HorizontalGroup group={group} />
            </Box>

            <Box display={{ base: 'flex', xl: 'none' }}>
              <ActionGroup group={group} />
            </Box>

            <Box
              w='2px'
              h='40%'
              mx='3'
              bg='editor.actionBarDivider'
              borderRadius='2px'
            />
          </HStack>
        ))}

        <EditorZoomSection />
      </HStack>
    </HStack>
  );
};
