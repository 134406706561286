import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/Share/ShareSection';
import { BooleanOperations } from '$/pages/EditorPage/components/AttributesSection/components/BooleanOperations';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorRoute } from '$/routes/Editor/EditorRoute';

export const LayerSidebar = () => {
  const { t } = useTranslation();

  const { setLayerSize, repositionLayer } = useEditorActions();
  const layerIndex = useEditorStore.useActiveLayerIndex();
  const componentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();

  const { projectId } = EditorRoute.useSearch();

  if (componentIndex == null || layerIndex == null) return;

  const layer = components[componentIndex].layers[layerIndex];

  if (layer == null) return;

  return (
    <Stack w='full'>
      <ShareSection px='4' pt='2' type='editor' projectId={projectId} />
      <SidebarDivider />
      <CurrentColorSection />
      <VisualizationSection />
      <SidebarDivider />
      <Stack w='full' px='4'>
        <SectionTitle title={t('editor.dimensions.title')} icon='size' />
        <EditorNumberInput
          text={t('editor.dimensions.width')}
          initialValue={layer.width}
          onChange={(val) =>
            setLayerSize(componentIndex, layerIndex, val, layer.height)
          }
          minValue={1}
        />
        <EditorNumberInput
          text={t('editor.dimensions.height')}
          initialValue={layer.height}
          onChange={(val) =>
            setLayerSize(componentIndex, layerIndex, layer.width, val)
          }
          minValue={1}
        />
      </Stack>
      <SidebarDivider />
      <Stack w='full' px='4'>
        <SectionTitle title={t('editor.position.title')} icon='position' />
        <EditorNumberInput
          text='X'
          initialValue={layer.x}
          onChange={(val) =>
            repositionLayer(componentIndex, layerIndex, val, layer.y)
          }
        />
        <EditorNumberInput
          text='Y'
          initialValue={layer.y}
          onChange={(val) =>
            repositionLayer(componentIndex, layerIndex, layer.x, val)
          }
        />
      </Stack>
      <SidebarDivider />
      <VisibilityToggle />

      <SidebarDivider />
      <BooleanOperations />
    </Stack>
  );
};
