import { ResponsiveValue } from '@chakra-ui/react';
import { Property } from 'csstype';
import { useTranslation } from 'react-i18next';
import { ZodError } from 'zod';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useToast } from '$/hooks/useToast';
import { logError } from '$/logger';
import {
  ModuleReceiveAction,
  ModuleSendAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import {
  ModuleSendMessageAction,
  useEditorActions,
} from '$/pages/EditorPage/hooks/useEditorActions';
import { useSaveBeforeLeaveModalStore } from '$/pages/EditorPage/hooks/useSaveBeforeLeaveModalStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { EditorRoute } from '$/routes/Editor/EditorRoute';
import { EditorStatusSchema } from '$/services/usecases/editor/mapper/editorStatus';
import { HoverStatusSchema } from '$/services/usecases/editor/mapper/hoverStatus';
import { ModuleCommandResponseSchema } from '$/services/usecases/editor/mapper/moduleCommandResponse';
import { ModuleContextMenuSchema } from '$/services/usecases/editor/mapper/moduleContextMenu';
import { SaveProjectResponseSchema } from '$/services/usecases/editor/mapper/saveProjectResponse';
import {
  addProjectIdToEditorProject,
  createEditorProject,
} from '$/services/usecases/projects';
import { isDevMode } from '$/utils/generalUtils';

export type ModuleReceiveMessage = {
  action: ModuleReceiveAction;
} & Record<string, unknown>;

const statusSyncUpdateBlacklist: ModuleSendMessageAction[] = [
  ModuleSendAction.SetMaterial,
  ModuleSendAction.GetModuleState,
  ModuleSendAction.SetBackgroundColor,
];

export const useEditorModuleListener = () => {
  const { projectId: id } = EditorRoute.useSearch();
  const toast = useToast();

  const { t } = useTranslation();

  const user = useAuthenticationStore.useUser();

  const setIsEditorModuleLoading = useEditorStore.useSetIsEditorModuleLoading();
  const setIsProjectLoading = useEditorStore.useSetIsProjectLoading();
  const setHoveredObject = useEditorStore.useSetHoveredObject();
  const syncState = useEditorStore.useSyncState();
  const setModuleContextMenu = useEditorStore.useSetModuleContextMenu();
  const editorProject = useEditorStore.useEditorProject();
  const projectName = useEditorStore.useProjectName();
  const freeUserToken = useEditorStore.useFreeUserToken();
  const {
    loadPicture,
    createComponent,
    getModuleState,
    loadProject,
    refreshBackgroundColor,
    renameObject,
  } = useEditorActions();

  const showReceivedToast = (
    title: string,
    content: string,
    success: boolean = true,
    color?: ResponsiveValue<Property.Color>,
  ) => {
    toast(title, success ? 'success' : 'error', content, {
      customIcon: 'arrow_right',
      customColor: color,
      customIconProps: { transform: 'rotate(180deg)' },
    });
  };

  const onSavedProject = async (projectId: string) => {
    if (id == null || user?.id !== editorProject?.userId) {
      const response = await createEditorProject(
        projectId,
        projectName ?? t('editor.new_project'),
        true,
      );

      if (response.isSuccessful) {
        const newURL = new URL(window.location.href);
        newURL.search = `?projectId=${response.response.payload.projectId}`;
        window.history.replaceState({ path: newURL.href }, '', newURL.href);
        toast(
          t('editor.projectSaved'),
          'success',
          t('editor.projectSavedDescription'),
          { customIcon: 'check_icon' },
        );
      }
    } else {
      await addProjectIdToEditorProject(id, projectId);
      toast(
        t('editor.projectSaved'),
        'success',
        t('editor.projectSavedDescription'),
        { customIcon: 'check_icon' },
      );
    }

    useEditorStore.setState({ isSaving: false });
    useSaveBeforeLeaveModalStore.setState({
      previousComponentState: JSON.stringify(
        useEditorStore.getState().components,
      ),
      hasDiscarded: false,
      isLoading: false,
      isModalOpen: false,
    });
  };

  const moduleMessageListener = async (event: MessageEvent) => {
    const data = (
      typeof event.data == 'string' ? JSON.parse(event.data) : event.data
    ) as ModuleReceiveMessage;

    if (data.action == null) {
      logError({
        message: 'Unexpected message format received',
        data,
      });
      return;
    }
    data.action = data.action.toLocaleLowerCase() as ModuleReceiveAction;

    // Keep the log and the toasts until we always got the messages reliably
    console.log('RECEIVED MESSAGE', data);

    switch (data.action) {
      case ModuleReceiveAction.Ready:
        setIsEditorModuleLoading(false);

        if (isDevMode) {
          showReceivedToast(
            `Module Ready`,
            JSON.stringify(data),
            true,
            '#a903fc',
          );
        }

        if (
          editorProject?.projectIds != null &&
          editorProject.projectIds.length > 0
        ) {
          loadProject(editorProject.projectIds[0]);
        } else if (editorProject?.token != null) {
          loadPicture(editorProject.token);
        } else if (freeUserToken != null) {
          loadPicture(freeUserToken);
        }
        break;
      case ModuleReceiveAction.ProjectLoaded:
        setIsProjectLoading(false);
        refreshBackgroundColor();

        if (isDevMode) {
          showReceivedToast(
            `Project Loaded`,
            JSON.stringify(data),
            true,
            '#a903fc',
          );
        }

        getModuleState();
        break;
      case ModuleReceiveAction.StatusSync:
        try {
          const statusMessage = EditorStatusSchema.parse(data);
          statusMessage.components.map((component, componentIndex) => {
            component.layers = component.layers.map((layer, layerIndex) => {
              if (layer.name != '') {
                return layer;
              }
              const previousTypeLayerCount = component.layers
                .slice(0, layerIndex)
                .filter((l) => l.type === layer.type).length;

              let translationKey = `editor.${layer.type}.layerName`;
              translationKey = translationKey.replace('poly', 'path');

              const name = t(translationKey, {
                amount: previousTypeLayerCount + 1,
              });
              layer.name = name;
              renameObject(componentIndex, layerIndex, name);

              return layer;
            });
          });
          syncState(statusMessage, data);
          if (statusMessage.components.length === 0) {
            const componentName = t('editor.component_name', {
              letter: 1,
            });
            createComponent(componentName);
          }
          const previousSavedState =
            useSaveBeforeLeaveModalStore.getState().previousComponentState;
          if (previousSavedState === '') {
            useSaveBeforeLeaveModalStore.setState({
              previousComponentState: JSON.stringify(statusMessage.components),
            });
          }
        } catch (error) {
          const zodErrors = (error as ZodError).errors.map((error) => ({
            message: error.message,
            path: error.path.join(' -> '),
          }));
          if (isDevMode) {
            showReceivedToast(
              `ValidationError`,
              JSON.stringify(zodErrors),
              false,
            );
          }
          logError(zodErrors);
        }
        break;
      case ModuleReceiveAction.CommandReceived: {
        const response = ModuleCommandResponseSchema.parse(data);
        if (
          statusSyncUpdateBlacklist.includes(response.data.vars.action ?? '') ||
          response.data.vars.action?.includes('hover')
        )
          break;
        getModuleState();
        break;
      }
      case ModuleReceiveAction.NewLayer: {
        getModuleState();
        break;
      }
      case ModuleReceiveAction.HoverObject: {
        const { elementIndex, layerIndex } = HoverStatusSchema.parse(data.data);
        setHoveredObject(elementIndex, layerIndex);
        break;
      }
      case ModuleReceiveAction.HoverOffObject: {
        setHoveredObject(null, null);
        break;
      }
      case ModuleReceiveAction.RightClick: {
        const moduleData = ModuleContextMenuSchema.parse(data.data);
        setModuleContextMenu(moduleData);
        break;
      }
      case ModuleReceiveAction.ProjectSaved: {
        const { projectId } = SaveProjectResponseSchema.parse(data);
        await onSavedProject(projectId);

        break;
      }
      default:
        return; // this switch will be filled with actions depending on the actions sent by the module
    }
  };

  return { moduleMessageListener };
};
