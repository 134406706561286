import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

type State = {
  isModalOpen: boolean;
  previousComponentState: string;
  discardedState: string;
  hasDiscarded: boolean;
  isLoading: boolean;
};

type Actions = {
  onModalClose: () => void;
  onModalOpen: () => void;
};

const initial: State = {
  isModalOpen: false,
  previousComponentState: '',
  discardedState: '',
  hasDiscarded: false,
  isLoading: false,
};

const store = create<State & Actions>()((set) => ({
  ...initial,
  onModalClose: () => set({ isModalOpen: false }),
  onModalOpen: () => set({ isModalOpen: true }),
}));

export const useSaveBeforeLeaveModalStore = createSelectorHooks(store);
