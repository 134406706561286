import {
  Container,
  Link,
  ListItem,
  OrderedList,
  Spinner,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
  DOMNode,
  attributesToProps,
} from 'html-react-parser';
import { FC, useEffect, useState } from 'react';

import { trackContentInteraction } from '$/logger';
import { PiwikContentTarget } from '$/utils/piwikUtils';

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.attribs) {
      const children = domNode.children;
      const props = attributesToProps(domNode.attribs);

      switch (domNode.tagName) {
        case 'p':
          return (
            <Text mb='2'>{domToReact(children as DOMNode[], options)}</Text>
          );
        case 'a':
          delete props.style;

          return (
            <Link
              mb='2'
              color='primaryBlue.500'
              {...props}
              onClick={() => {
                if (
                  props.href !== null &&
                  typeof props.href === 'string' &&
                  props.href.includes('DAW_Code_of_Conduct_')
                ) {
                  const language = props.href
                    .split('DAW_Code_of_Conduct_')[1]
                    .replace('.pdf', '');
                  trackContentInteraction(
                    'ClickButton',
                    'Menu',
                    'OpenIntegrityDocument',
                    language as PiwikContentTarget,
                  );
                }
              }}
            >
              {domToReact(children as DOMNode[], options)}
            </Link>
          );
        case 'dl':
        case 'ul':
          return (
            <UnorderedList>
              {domToReact(children as DOMNode[], options)}
            </UnorderedList>
          );
        case 'ol':
          return (
            <OrderedList>
              {domToReact(children as DOMNode[], options)}
            </OrderedList>
          );
        case 'li':
          return (
            <ListItem>{domToReact(children as DOMNode[], options)}</ListItem>
          );
        default:
          break;
      }
    }
  },
};
interface Props {
  contentEndpoint: string;
}

export const HtmlContent: FC<Props> = ({ contentEndpoint }) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await fetch(contentEndpoint);
      const data = (await response.json()) as string[];
      setContent(data.join(' '));
    };
    void loadContent();
  }, [contentEndpoint]);

  if (content == null) {
    return (
      <Spinner w='48px' h='48px' emptyColor='neutral.1000/30' thickness='4px' />
    );
  }

  return (
    <Container
      layerStyle='DashboardSection'
      maxW='container.lg'
      my='10'
      p='8'
      fontSize='sm'
    >
      <VStack alignItems='flex-start'>{parse(content, options)}</VStack>
    </Container>
  );
};
