import { Box, useDisclosure } from '@chakra-ui/react';
import { useBlocker } from '@tanstack/react-router';
import { useEffect } from 'react';

import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { AppliedColorBar } from '$/components/core/Editor/Mobile/AppliedColorBar';
import { BottomActionBar } from '$/components/core/Editor/Mobile/BottomActionBar';
import { TopActionBar } from '$/components/core/Editor/Mobile/TopActionBar';
import { TurnScreenModal } from '$/components/core/Editor/TurnScreenModal';
import { useIsMobile } from '$/hooks/useIsMobile';
import { useKeyPress } from '$/hooks/useKeyPress';
import { ActionBarSection } from '$/pages/InspirationEditorPage/components/ActionBarSection';
import { InspirationAttributesSection } from '$/pages/InspirationEditorPage/components/AttributeSection';
import { ImageSection } from '$/pages/InspirationEditorPage/components/ImageSection';
import { InspirationFullscreenModal } from '$/pages/InspirationEditorPage/components/InspirationFullscreenModal';
import { InspirationMobileFilterDrawer } from '$/pages/InspirationEditorPage/components/InspirationMobileFilterDrawer';
import { LayerSection } from '$/pages/InspirationEditorPage/components/LayerSection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { useCollectionStore } from '$/stores/useCollectionStore';

const DesktopContent = () => {
  useKeyPress(({ key }) => {
    if (key === 'Escape')
      useInspirationEditorStore.getState().setActiveComponentId(null);
  });

  return (
    <>
      <LayerSection />
      <InspirationAttributesSection />
      <ActionBarSection groups={[]} />
      <InspirationFullscreenModal />
      <TurnScreenModal />
    </>
  );
};

const MobileContent = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const scene = useInspirationEditorStore.useScene();

  useInspirationEditorStore.subscribe((state, prev) => {
    const isInitial = !prev.activeComponentId && state.activeComponentId;
    const isChange = prev.activeComponentId && state.activeComponentId;
    const isDifferent = prev.activeComponentId !== state.activeComponentId;
    if (isInitial || (isChange && isDifferent)) onOpen();
  });

  return (
    <>
      <TopActionBar name={scene?.name} />
      <AppliedColorBar onOpen={onOpen} />
      <BottomActionBar mode='inspiration' />
      <InspirationMobileFilterDrawer isOpen={isOpen} onClose={onClose} />
      <Box pos='relative' zIndex={-2} w='100vw' h='100dvh' />
    </>
  );
};

export const InspirationEditorPage = () => {
  const isMobile = useIsMobile({ sm: true });

  const userRole = useAuthenticationStore.useUserRole();
  const modalOpen = useAuthenticationStore.useIsModalOpen();

  const scene = useInspirationEditorStore.useScene();
  const user = useAuthenticationStore.useUser();
  const setHasUser = useCollectionStore.useSetHasUser();

  useEffect(() => {
    void setHasUser(user?.id != null);
  }, [setHasUser, user?.id]);

  useBlocker(
    () => useAuthenticationStore.getState().onModalOpen('loginToSave'),
    userRole === 'free' && !modalOpen,
  );

  return (
    <>
      {scene && <ImageSection id={scene.id} />}
      {!isMobile && <DesktopContent />}
      {isMobile && <MobileContent />}
      <AuthenticationModal />
    </>
  );
};
