import { Center, HStack, Text } from '@chakra-ui/react';
import { useRouterState } from '@tanstack/react-router';
import { FunctionComponent, ReactNode } from 'react';

import { StyledLink, TypedToOptions } from '$/components/core/StyledLink';

interface INavbarLinkProps {
  icon: ReactNode;
  title: string;
  to: Exclude<TypedToOptions, undefined>;
  backgroundColor?: string;
  onClick?: VoidFunction;
}

export const NavbarLink: FunctionComponent<INavbarLinkProps> = ({
  backgroundColor,
  icon,
  to,
  title,
  onClick,
}) => {
  const { pathname } = useRouterState().location;
  const isWelcomeDashboardActive = to === '/' && pathname === '/';
  const isActive =
    (pathname.startsWith(to) && to !== '/') || isWelcomeDashboardActive;

  const bg = isActive
    ? backgroundColor ?? 'secondaryBackground'
    : 'transparent';

  return (
    <StyledLink
      link={{ to, params: {}, search: {} }}
      w='full'
      px='4'
      py='3'
      bgColor={bg}
      _hover={{ bg: !isActive ? 'bodyBackground' : bg }}
      onClick={onClick}
    >
      <HStack gap='4' w='full'>
        <Center w='24px' h='24px'>
          {icon}
        </Center>
        <Text
          display={{ base: 'block', mobile: 'none', md: 'block' }}
          color='text'
          fontSize='md'
          fontWeight='bold'
        >
          {title}
        </Text>
      </HStack>
    </StyledLink>
  );
};
