import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useBlocker } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useSaveBeforeLeaveModalStore } from '$/pages/EditorPage/hooks/useSaveBeforeLeaveModalStore';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const SaveBeforeLeaveModal = () => {
  const { t } = useTranslation();
  const { saveProject } = useEditorActions();
  const isOpen = useSaveBeforeLeaveModalStore.useIsModalOpen();
  const onOpen = useSaveBeforeLeaveModalStore.useOnModalOpen();
  const hasDiscarded = useSaveBeforeLeaveModalStore.useHasDiscarded();
  const onCloseModal = useSaveBeforeLeaveModalStore.useOnModalClose();
  const components = useEditorStore.useComponents();
  const previousComponentState =
    useSaveBeforeLeaveModalStore.usePreviousComponentState();
  const discardedState = useSaveBeforeLeaveModalStore.useDiscardedState();
  const isLoading = useSaveBeforeLeaveModalStore.useIsLoading();

  const hasUnsavedChanges =
    JSON.stringify(components) !== previousComponentState;

  const changesAfterDiscard =
    hasDiscarded && discardedState !== JSON.stringify(components);

  const showModal = hasUnsavedChanges && (!hasDiscarded || changesAfterDiscard);

  useBlocker(() => onOpen(), showModal);

  const onClose = () => {
    useSaveBeforeLeaveModalStore.setState({
      hasDiscarded: true,
      discardedState: JSON.stringify(components),
    });
    onCloseModal();
  };

  const onSave = () => {
    useSaveBeforeLeaveModalStore.setState({ isLoading: true });
    saveProject();
  };

  return (
    <Modal
      closeOnOverlayClick={!isLoading}
      isCentered
      isOpen={isOpen && showModal}
      onClose={onCloseModal}
    >
      <ModalOverlay />
      <ModalContent w='20.125rem'>
        <ModalBody p='4'>
          <Text color='text' fontSize='sm'>
            {t('editor.saveUnchangedChangesModal.description')}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack justify='center' gap='2' w='full'>
            <Button
              fontSize='sm'
              isLoading={isLoading}
              onClick={onClose}
              variant='text'
            >
              {t('dashboard.authentication.loginToSave.close')}
            </Button>
            <Button fontSize='sm' isLoading={isLoading} onClick={onSave}>
              {t('editor.save')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
