import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Icon } from '$/components/common/Icon';

interface Props extends Omit<TooltipProps, 'children'> {
  info: string | ReactNode;
  icon?: ReactNode;
}

export const InfoTooltip: FC<Props> = ({ info, icon, ...props }) => {
  return (
    <Tooltip
      p='3'
      color='text'
      bg='background'
      border='1px'
      borderColor='border'
      label={info}
      {...props}
    >
      <Box>
        {icon ? icon : <Icon icon='info' color='border' cursor='help' />}
      </Box>
    </Tooltip>
  );
};
