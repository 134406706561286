import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

import { colors } from '$/theme/colors';
import { components } from '$/theme/components';
import { layerStyles } from '$/theme/layers';
import { sizes } from '$/theme/sizes';
import { semanticTokens } from '$/theme/tokens';

export const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const extendedTheme = extendTheme(
  {
    config,
    components,
    semanticTokens,
    layerStyles,
    sizes,
    fonts: {
      heading: `'Gotham', sans-serif`,
      body: `'Gotham', sans-serif`,
    },
    space: {
      5.75: '1.438rem',
    },
    breakpoints: {
      mobile: '500px',
      sm: '757px',
      md: '1172px',
      lg: '1440px',
      xl: '1800px',
      '2xl': '2000px',
    },
    fontSizes: {
      xss: '10px',
      xs: '12px',
      sm: '14px',
      md: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '24px',
      '3xl': '28px',
      '4xl': '36px',
      '5xl': '48px',
      h5: '48px',
      h7: '32px',
      h10: '20px',
    },
    fontWeights: {
      light: 325,
      normal: 400,
    },
    lineHeights: {
      shorter: '16px',
      short: '20px',
      normal: '24px',
      base: '32px',
      tall: '48px',
      taller: '56px',
      standard: 'normal',
    },
    styles: {
      global: {
        h1: {
          fontSize: '4xl',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 'taller',
        },
        h2: {
          fontSize: '3xl',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 'taller',
        },
        h3: {
          fontSize: '2xl',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 'base',
        },
        h4: {
          fontSize: 'xl',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 'normal',
        },
        body: {
          fontSize: 'md',
          fontStyle: 'normal',
          fontWeight: 'light',
          lineHeight: 'normal',
          bg: 'bodyBackground',
        },
      },
    },
  },
  withProse(),
) as Record<string, unknown>;

export const theme = { ...extendedTheme, colors };
