import { Box, Collapse, Stack, useDisclosure } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { For } from '$/components/common/Flow/For';
import { MaterialListItem } from '$/components/core/Collection/MaterialListItem';
import { MaterialListItemSkeleton } from '$/components/core/Collection/MaterialListItemSkeleton';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { Layer } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { repeat } from '$/utils/arrayUtils';
import { unsecureHashCode, unsecureSeededIntRange } from '$/utils/numberUtils';

interface Props {
  items: Layer[];
  label: string;
  icon: ReactNode;
  isLoading: boolean;
}

export const SceneDetailSection: FC<Props> = ({
  items,
  label,
  icon,
  isLoading,
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  if (items.length === 0 && !isLoading) return null;

  return (
    <Stack gap='0' px='4' pt='3' pb='5'>
      <Box onKeyDown={({ key }) => key === 'Enter' && onToggle()} tabIndex={0}>
        <FilterHeader
          header={label}
          isActive={isOpen}
          onClick={onToggle}
          prefix={icon}
          isCollapsible
        />
      </Box>

      <Collapse in={isOpen}>
        <Stack pt='4'>
          <For
            each={items}
            empty={() => null}
            isLoading={isLoading}
            fallback={() =>
              repeat(unsecureSeededIntRange(unsecureHashCode(label), 1, 3)).map(
                (id) => <MaterialListItemSkeleton key={id} id={id} />,
              )
            }
          >
            {({ material }, i) => (
              <MaterialListItem id={i} material={material} size='sm' />
            )}
          </For>
        </Stack>
      </Collapse>
    </Stack>
  );
};
