import { chakra, shouldForwardProp } from '@chakra-ui/react';
import { Variants, isValidMotionProp, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

const menu: Variants = {
  closed: {
    scale: 0,
    transition: {
      delay: 0.15,
    },
  },
  open: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 0.4,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
};

interface Props {
  children: ReactNode;
  isOpen: boolean;
  gap?: string;
}

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const DropdownContent: FC<Props> = ({
  children,
  isOpen,
  gap = '10px',
}) => {
  return (
    <MotionBox
      initial='closed'
      exit='closed'
      animate={isOpen ? 'open' : 'closed'}
      variants={menu}
      style={{ transformOrigin: 'top right' }}
      position='absolute'
      right='0'
      top={`calc(100% + ${gap})`}
      transformOrigin='top right'
      background='background'
      boxShadow='none'
      borderRadius='0 0 0.5rem 0.5rem'
      border='1px'
      borderColor='border'
      zIndex={10}
      maxH='20rem'
      overflowY='auto'
      width='full'
    >
      {children}
    </MotionBox>
  );
};
